<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container v-if="!loading">
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="elevation-x pb-3">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h5 font-alt">
                Admission du {{ $dayjs(stay.startDate).format('LL') }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-alt font-weight-bold text-caption">
                {{ stay.service.label }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="primary--text mt-2">
                <v-btn x-small class="primary--text" text :to="{name: 'surveys-pending'}">
                  <font-awesome-icon :icon="['fal','chevron-left']" class="mr-2" size="sm" />
                  Retour à la liste
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-avatar size="54">
              <v-img src="@/assets/img/user-generic.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-light">
                {{ stay.patientIdentity.firstName }} {{ stay.patientIdentity.lastName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-subtitle class="text-caption font-alt">Téléphone fixe</v-list-item-subtitle>
              <v-list-item-title>
                {{ stay.patientIdentity.phone || 'Non renseigné' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-subtitle class="text-caption font-alt">Téléphone mobile</v-list-item-subtitle>
              <v-list-item-title>
                {{ stay.patientIdentity.mobilePhone || 'Non renseigné' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <v-card v-if="$auth.granted('role_professional')" class="elevation-x pb-0 mt-5">
          <v-card-text>
            <div class="font-alt text-body-1 font-weight-light mb-3">Notes :</div>
            <v-textarea v-model="model.content" hide-details="auto" rows="1" auto-grow dense outlined class="align-center" label="Laisser une note">
              <template v-slot:append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small v-bind="attrs" color="success" height="40" :loading="submittingNote" v-on="on" @click.prevent="saveNote">
                      <font-awesome-icon :icon="['fal', 'save']" size="2x" />
                    </v-btn>
                  </template>
                  <span>Enregistrer ma note</span>
                </v-tooltip>
              </template>
            </v-textarea>
            <template v-if="notes">
              <v-list-item v-for="(note, index) in notes" :key="index" class="px-0 mt-3">
                <v-list-item-avatar size="38" class="mr-1">
                  <font-awesome-icon :icon="['fad', 'sticky-note']" size="2x" class="yellow--text text--darken-3" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ note.content }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ note.author.identity.firstName }} {{ note.author.identity.lastName }}
                    <span class="font-weight-bold">le {{ $dayjs(note.creationDate).format('LLL') || 'Non renseignée' }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card class="elevation-x">
          <v-card-title class="pb-3 font-alt">Questionnaire de suivi post-opératoire</v-card-title>
          <v-card-subtitle class="font-weight-light text-caption font-alt">
            Ce questionnaire permet de vous suivre et vous accompagner après votre admission.
          </v-card-subtitle>
          <v-card-text>
            <v-col v-for="(surveyStepId, index) in surveyStepsId" :key="surveyStepId" cols="12">
              <survey-step :step="surveyStep(surveyStepId)"
                           :response.sync="surveyResponse"
                           :is-last="index === surveyStepsId.length - 1"
                           @add-step="id => addStep(index, id)"
                           @next-step="id => nextStep(index, id)"
              />
            </v-col>
            <v-skeleton-loader v-if="responseLoading" type="article" class="mt-3" />
            <v-textarea v-if="$auth.granted('role_professional')" v-model="acknowledgmentComment"
                        outlined dense hide-details="auto" label="Ajouter un commentaire d'acquittement" class="px-3 py-2"
            />
          </v-card-text>
          <v-card-actions class="px-4 pt-0 flex-wrap">
            <v-spacer />
            <v-btn small color="secondary" elevation="0" class="mr-2 mb-1"
                   :disabled="!$auth.granted('role_professional') && !endReached" :loading="submitting"
                   @click.prevent="submitDialog = true"
            >
              {{ submitLabel }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <modal v-model="submitDialog" title="Valider mon questionnaire" color="color-dialog informations"
           subtitle="Une fois votre formulaire soumis, il ne pourra plus être modifié. Êtes-vous certain de vouloir soumettre votre questionnaire?"
           :img="require('@/assets/img/illustrations/new-entry.svg')" :actions="true"
    >
      <template v-slot:actions>
        <v-btn color="primary" small text @click.prevent="submit">
          J'ai compris
        </v-btn>
      </template>
    </modal>
  </v-container>
  <loader v-else />
</template>

<script>
  import api from '@/services/api'
  import * as surveys from '@/modules/surveys/api'
  import SurveyStep from '@/modules/surveys/components/form/SurveyStep'
  import Loader from '@/modules/core/components/Loader'
  import {column, concat, removeItem} from '@/utils/array'
  import logger from '@/services/logger'
  import { empty } from '@/utils/variables'
  import * as notes from '@/modules/surveys/api/notes'
  import Modal from '@/modules/core/components/Modal'

  export default {
    name: 'SurveyCard',
    components: {SurveyStep, Loader, Modal},
    props: {
      id: String
    },
    data() {
      return {
        loading: true,
        submitDialog: false,
        responseLoading: false,
        submitting: false,
        endReached: false,
        staySurvey: null,
        survey: null,
        surveyResponse: null,
        acknowledgmentComment: null,
        surveyStepsId: [],
        submittingNote: false,
        notes: [],
        model : {
          content: ''
        }
      }
    },
    computed: {
      stay() {
        return this.staySurvey.stay
      },
      surveyStep() {
        return id => this.survey.surveySteps.find(step => step.id === id)
      },
      submitLabel() {
        return this.$auth.granted('role_professional') ? 'Valider et acquitter' : 'Valider'
      }
    },
    async created() {
      this.staySurvey = (await surveys.staySurveys.get(this.id)).data
      this.survey = (await api.get(this.staySurvey.survey)).data

      if (this.staySurvey.surveyResponseId) {
        this.surveyResponse = (await surveys.surveyResponses.get(this.staySurvey.surveyResponseId)).data
      } else {
        this.surveyResponse = (await surveys.surveyResponses.create({
          staySurvey: this.staySurvey['@id']
        })).data
        this.$set(this.surveyResponse, 'surveyElementResponses', [])
      }
      this.surveyStepsId.push(this.survey.surveySteps.find(step => step.position === 1).id)
      if(this.$auth.granted('role_professional')) {
        await this.getSurveyNote()
      }
      this.loading = false
    },
    methods: {
      async saveNote() {
        this.submittingNote = true
        try {
          this.model.staySurvey = this.staySurvey['@id']
          await notes.create(this.model)
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submittingNote = false
          this.model.content = ''
          await this.getSurveyNote()
        }
      },
      async getSurveyNote() {
        try {
          await notes.list({
            params: {
              staySurvey: this.staySurvey.id
            }
          }).then((response) => {
            this.notes = response.data['hydra:member']
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        }
      },
      scrollToBottom() {
        this.$vuetify.goTo(this.pageHeight)
      },
      addStep(index, id) {
        if(id) {
          this.surveyStepsId.push(id)
          this.endReached = false
        }
        else {
          this.endReached = true
        }
      },
      // If id is null, the end is reached
      async nextStep(index, id) {
        let position = index + 1

        // Remove steps that stemmed from previous response
        this.surveyStepsId.splice(position, this.surveyStepsId.length - position)

        this.responseLoading = true

        // Copy array of current steps in order to not modify current display (by adding the new step) before removing steps in api
        let usedStepsId = [...this.surveyStepsId]
        if(id) {
          usedStepsId.push(id)
        }
        let usedElementsId = column(concat(this.surveyStepsId.map(stepId => this.survey.surveySteps.find(step => step.id === stepId).surveyElements)), 'id')

        let unusedResponseElements = this.surveyResponse.surveyElementResponses.filter(element => {
          return !usedElementsId.includes(typeof element.surveyElement === 'string' ? element.surveyElement : element.surveyElement.id)
        })

        // Remove unused response elements
        try {
          await Promise.all(unusedResponseElements.map(responseElement => {
            removeItem(this.surveyResponse.surveyElementResponses, responseElement)
            return surveys.surveyElementResponses.delete(responseElement.id)
          }))
        }
        catch (e) {
          logger.error(e)
        }

        // Add new stay or enable submit button
        if(id) {
          this.$nextTick(() => {
            this.surveyStepsId.push(id)
          })
          this.endReached = false
        }
        else {
          this.endReached = true
        }
        this.responseLoading = false
      },
      async submit() {
        this.submitting = true
        this.submitDialog = false

        if (this.$auth.granted('role_professional')) {
          if(!empty(this.acknowledgmentComment)) {
            await api.put(this.staySurvey['@id'], {
              surveyResponseStatus: 'acknowledged',
              surveyResponseAcknowledgmentComment: this.acknowledgmentComment
            })
          }
        }

        await surveys.submitSurveyResponse(this.surveyResponse.id)

        if (this.$auth.granted('role_professional')) {
          await this.$router.push({name: 'surveys-pending'})
        } else {
          await this.$router.push({name: 'patient-homepage'})
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: 'Questionnaire répondu avec succès'
          })
        }
        this.submitting = false
      }
    }
  }
</script>

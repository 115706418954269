<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div class="pb-2">
    <v-alert v-if="advice" dense prominent text color="info">
      <font-awesome-icon :icon="['fal','info-circle']" class=" mr-2" size="1x" />
      {{ advice.surveyAdvice.content }}
    </v-alert>
    <label v-if="question" class="font-weight-bold text-subtitle-1 grey--text text--darken-2">{{ question.surveyQuestion.label }}</label>
    <div v-if="question">
      <template v-if="questionType === 'text'">
        <v-textarea :value="responseValue" hide-details="auto" outlined flat dense :rules="[$rules.maxLength(255)]" @change="reply" />
      </template>
      <template v-else-if="questionType === 'boolean'">
        <v-select :value="responseValue" hide-details="auto"
                  :items="[{text: 'Oui', value: '1'}, {text: 'Non', value: '0'}]" outlined flat dense
                  @change="reply"
        />
      </template>
      <template v-else-if="questionType === 'choice'">
        <v-select :value="responseValue" hide-details="auto"
                  :items="question.surveyQuestion.surveyChoices"
                  item-text="label"
                  item-value="label"
                  outlined flat dense return-object
                  @change="reply"
        />
      </template>
      <template v-else-if="questionType === 'numeric'">
        <v-slider :value="responseValue" :thumb-size="24" min="1" max="10" tick-size="4"
                  :tick-labels="rangeSlider" @change="reply"
        />
      </template>
    </div>
    <div v-if="!conditions.length && isLast && step.nextSurveyStep">
      <v-btn @click.prevent="$emit('next-step', step.nextSurveyStep)">Continuer</v-btn>
    </div>
  </div>
</template>

<script>
  import * as surveys from '@/modules/surveys/api'

  export default {
    name: 'SurveyStep',
    props: {
      step: Object,
      response: Object,
      isLast: Boolean
    }, data() {
      return {
        rangeSlider: [...Array(10).keys()].map(i => i + 1)
      }
    },
    computed: {
      localResponse: {
        get() {
          return this.response
        },
        set(value) {
          this.$emit('update:response', value)
        }
      },
      responseValue() {
        return this.responseElement ? (this.questionType === 'choice') ? this.responseElement.surveyChoices[0] : this.responseElement.response : null
      },
      responseElement() {
        return this.question && this.localResponse ? this.response.surveyElementResponses.find(element => {
          return typeof element.surveyElement === 'string' ? element.surveyElement === this.question.id : element.surveyElement.id === this.question.id
        }) : null
      },
      advice() {
        return this.step.surveyElements.find(element => element.type === 'advice')
      },
      question() {
        return this.step.surveyElements.find(element => element.type === 'question')
      },
      questionType() {
        return this.question.surveyQuestion.type
      },
      conditions() {
        return this.step.surveyElements.filter(element => element.type === 'condition')
      }
    },
    created() {
      if (this.step.nextSurveyStep) {
        this.$emit('add-step', this.step.nextSurveyStep)
      } else if (!this.conditions.length) {
        this.$emit('add-step', null)
      } else if (this.responseElement) {
        this.$emit('add-step', this.computeResponse(this.questionType === 'choice' ? this.responseElement.surveyChoices[0].label : this.responseElement.response))
      }
    },
    methods: {
      async reply(value) {
        let elementResponse
        if (this.questionType === 'choice') {
          elementResponse = {
            surveyChoices: [value.id]
          }
        } else {
          elementResponse = {
            response: value.toString()
          }
        }

        const responseData = {
          surveyResponse: this.response.id,
          surveyElement: this.question.id
        }

        if (this.responseElement) {
          const response = (await surveys.surveyElementResponses.update(this.responseElement.id, Object.assign(elementResponse, responseData))).data
          Object.assign(this.responseElement, response)
        } else {
          const response = (await surveys.surveyElementResponses.create(Object.assign(elementResponse, responseData))).data
          this.localResponse.surveyElementResponses.push(response)
        }

        // TODO Check in backoffice that only one condition can be resolved at a time (so no unpredictable next step)
        this.$emit('next-step', this.computeResponse(this.questionType === 'choice' ? value.label : value))
      },
      computeResponse(value) {
        let nextStep = this.step.nextSurveyStep

        for (let element of this.conditions) {
          let condition = element.surveyCondition
          let result = false
          switch (condition.type) {
            case 'simple':
              result = this.computeConditionPart(condition.surveyConditionParts[0], value)
              break
            case 'and':
              result = true
              for (let conditionPart of condition.surveyConditionParts) {
                if (!this.computeConditionPart(conditionPart, value)) {
                  result = false
                  break
                }
              }
              break
            case 'or':
              for (let conditionPart of condition.surveyConditionParts) {
                if (!this.computeConditionPart(conditionPart, value)) {
                  result = true
                  break
                }
              }
              break
          }

          if (result && condition.successSurveyStep) {
            nextStep = condition.successSurveyStep
            break
          } else if (!result && condition.failureSurveyStep) {
            nextStep = condition.failureSurveyStep
            break
          }
        }

        return nextStep
      },
      computeConditionPart(conditionPart, value) {
        let result = false
        if (this.questionType === 'choice') {
          result = conditionPart.surveyChoice.label === value
          let choice = value ? this.question.surveyQuestion.surveyChoices.find(choice => choice.label === value) : null
          if(choice) {
            result = choice.id === conditionPart.surveyChoice
          }
        } else {
          let conditionValue = conditionPart.conditionValue
          if (!isNaN(conditionPart.conditionValue)) {
            value = parseFloat(value)
            conditionValue = parseFloat(conditionPart.conditionValue)
          }

          switch (conditionPart.operator) {
            case 'EQUAL':
              result = value === conditionValue
              break
            case 'LESSER_THAN_OR_EQUAL':
              result = value <= conditionValue
              break
            case 'GREATER_THAN_OR_EQUAL':
              result = value >= conditionValue
              break
          }
        }
        return result
      }
    }
  }
</script>
